<template>
	<div id="fullpage">
		<full-page :options="options" ref="fullpage">
<!-- 			<div class="section">
				<el-carousel height="100vh" @change="change" :interval="5000">
					<el-carousel-item v-for="(item,index) in bannerList" :key="index">
						<el-image :src="item.image" :alt="item.title" fit="cover" class="image-wrap"></el-image>
						<div class="btn-wrap animate__animated"
							:class="current==0 && (bannerIndex==index)?'animate__fadeInDown':''">
							<div class="btn" :class="btnItem.type" v-for="(btnItem,btnIndex) in item.btnList"
								:key="btnIndex" @click="btntap(btnItem.link)">{{btnItem.name}}</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div> -->
			<div class="section" v-for="(item,index) in list" :key="index">
				<div class="videoContainer"  v-if="item.is_show_type==2"> 
					<video class="fullscreenVideo" id="bgVid" data-autoplay autoplay="autoplay" loop="loop"
						 muted="muted">
						<source :src="item.mp4_url">
					</video>
				</div>
				<template v-if="item.is_show_type==1">
					<img class="image" :src="item.image_max" v-if="browser=='Edge'">
					<iframe ref="iframes" :src="item.vr_url" frameborder="0" style="width: 100%;height: 100%;"
						v-else></iframe>
				</template>
				<img class="image" :src="item.image_max" v-if="item.is_show_type<1">
				<div class="btn-wrap animate__animated" :class="current==index+1?'animate__fadeInDown':''">
					<div class="btn" :class="btnItem.type" v-for="(btnItem,btnIndex) in item.btnList" :key="btnIndex"
						@click="btntap(btnItem.link)">{{btnItem.name}}</div>
				</div>
			</div>
<!-- 			<div class="section">
				<img class="image" src="../assets/images/index2022/P9.jpg">
				<div class="foot">
					<Footer2022></Footer2022>
				</div>
			</div> -->
		</full-page>
	</div>
</template>
<script>
	import utils from '@/common/utils'
	import Footer2022 from '@/components/footer2022.vue'
	import {
		mapState
	} from 'vuex'
	export default {
		// name: 'BackTop',
		components: {
			Footer2022,
		},
		props: {
			// bannerList: {
			// 	type: Array,
			// 	default: []
			// },
			list: {
				type: Array,
				default: []
			},
		},
		data() {
			return {
				options: {
					css3: true,
					scrollBar: true,
					afterLoad: this.afterLoad,
				},
				browser: '',
				current: 0,
				bannerIndex: 0,
				loop: true,
				iframeWin: {},
			}
		},
		watch: {
			// 监听事件，表单页面关闭时结束视频播放
			'visible': function(val) {
				if (this.visible === false) { //visible为表单显示状态
					let myVideo = document.getElementById('video') //对应video标签的ID
					myVideo.pause()
				}
			}
		},
		created() {
			//以下是调用上面的函数
			this.myBrowser();
		},
		mounted() {
			// 在外部vue的window上添加postMessage的监听，并且绑定处理函数handleMessage
			window.addEventListener('message', event => {
				// 根据上面制定的结构来解析iframe内部发回来的数据
				const data = event.data
				switch (data.cmd) {
					case 'returnFormJson':
						// 业务逻辑
						break
					case 'returnHeight':
						// 业务逻辑
						break
				}
			});
			this.iframeWin = this.$refs.iframes.contentWindow
		},
		// beforeRouteLeave(to, from, next) {
		// 	this.$refs.fullpage.api.destroy('all');
		// 	next();
		// },
		methods: {
			myBrowser() {
				var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
				var isOpera = userAgent.indexOf("Opera") > -1;
				if (isOpera) {
					this.browser = "Opera";
				}; //判断是否Opera浏览器
				if (userAgent.indexOf("Firefox") > -1) {
					this.browser = "FF";
				} //判断是否Firefox浏览器
				if (userAgent.indexOf("Chrome") > -1) {
					this.browser = "Chrome";
				}
				if (userAgent.indexOf("Safari") > -1) {
					this.browser = "Safari";
				} //判断是否Safari浏览器
				if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
					this.browser = "IE";
				};
				//判断是否IE浏览器
				if (userAgent.lastIndexOf("Trident") > -1) {
					this.browser = "Edge";
				}
			},
			// change: function(e) {
			// 	this.bannerIndex = e;
			// },
			sendMessage() {
				// 外部vue向iframe内部传数据
				this.iframeWin.postMessage({
					cmd: 'getFormJson',
					params: {}
				}, '*')
			},
			afterLoad: function(origin, destination, direction) {
				this.current = destination.index;
			},
			btntap: utils.debounce(function(e) {
				if (e.url) {
					window.open(e.url)
					return;
				}

				if (e.pathName == this.$route.name) {
					this.reload()
				}
				if (e.pathName) {
					this.$router.push({
						name: e.pathName
					});
				}
			}, 200, true),
		},

	}
</script>
<style lang="scss">
	#fullpage {
		.videoContainer {
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: -100;
		}

		#bgVid {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		.videoContainer:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			display: block;
			z-index: -1;
			top: 0;
			left: 0;
			background: rgba(25, 29, 34, .65);
		}

		.section {
			position: relative;

			.image-wrap {
				height: 100%;
				width: 100%;
			}

			.el-image__inner--center {
				top: 0 !important;
				left: 0 !important;
				transform: translate(0%, 0%) !important;
			}

			.image {
				width: 100%;
				object-fit: cover;
				height: 100%;
			}

			.btn-wrap {
				position: absolute;
				bottom: 100px;
				width: 100%;
				display: flex;
				justify-content: center;

				.animate__animated {
					&.animate__fadeInDown {
						--animate-duration: 2s;
					}
				}

				.btn {
					width: 256px;
					height: 40px;
					border-radius: 20px;
					font-size: 12px;
					line-height: 40px;
					text-align: center;
					margin: 0 20px;
					cursor: pointer;

					&.online {
						background: #222222;
						color: #FFFFFF;
					}

					&.more {
						background: #FFFFFF;
						color: #393C41;
					}

					&.reverse {
						background: #FFFFFF;
						color: #393C41;
					}
				}
			}

			.foot {
				position: absolute;
				bottom: 0;
				width: 100%;
			}
		}
	}
</style>

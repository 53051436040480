import { render, staticRenderFns } from "./fullpage.vue?vue&type=template&id=d981d4da"
import script from "./fullpage.vue?vue&type=script&lang=js"
export * from "./fullpage.vue?vue&type=script&lang=js"
import style0 from "./fullpage.vue?vue&type=style&index=0&id=d981d4da&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
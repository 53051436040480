<template>
	<div class="index-wrap">
		<Header2022></Header2022>
		<!-- <Fullpage :bannerList="bannerList" :list="list"></Fullpage> -->
		<full-page :options="options" ref="fullpage">
			<div class="section">
				<el-carousel height="100vh" @change="change" :interval="5000">
					<el-carousel-item v-for="(item,index) in bannerList" :key="index">
						<el-image :src="item.image" :alt="item.title" fit="cover" class="image-wrap"></el-image>
						<div class="btn-wrap animate__animated"
							:class="current==0 && (bannerIndex==index)?'animate__fadeInDown':''">
							<div class="btn" :class="btnItem.type" v-for="(btnItem,btnIndex) in item.btnList"
								:key="btnIndex" @click="btntap(btnItem.link)">{{btnItem.name}}</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="section" v-for="(item,index) in list" :key="index">
				<div class="videoContainer" v-if="item.type=='video'">
					<video class="fullscreenVideo" id="bgVid" data-autoplay autoplay="autoplay" loop="loop"
						 muted="muted">
						<source :src="item.videosrc">
					</video>
				</div>
				<template v-if="item.type=='iframe'">
					<img class="image" :src="item.image" v-if="browser=='Edge'">
					<iframe ref="iframe" :src="item.iframesrc" frameborder="0" style="width: 100%;height: 100%;"
						v-else></iframe>
				</template>
				<img class="image" :src="item.image" v-if="item.type=='img'">
				<div class="btn-wrap animate__animated" :class="current==index+1?'animate__fadeInDown':''">
					<div class="btn" :class="btnItem.type" v-for="(btnItem,btnIndex) in item.btnList" :key="btnIndex"
						@click="btntap(btnItem.link)">{{btnItem.name}}</div>
				</div>
			</div>
			<div class="section">
				<img class="image" src="../../assets/images/index2022/P9.jpg">
				<div class="foot">
					<Footer2022></Footer2022>
				</div>
			</div>
		</full-page>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import Fullpage from '@/components/fullpage.vue'
	import {
		myBrowser
	} from '@/common/app.js';
	export default {
		components: {
			Header2022,
			Footer2022,
			Fullpage
		},
		data() {
			return {
				fixStyle: {},
				options: {
					css3: true,
					scrollBar: true,
					afterLoad: this.afterLoad,
				},
				browser: '',
				current: 0,
				bannerIndex: 0,
				loop: true,
				iframeWin: {},
				bannerList: [{
						image: require("../../assets/images/index2022/smart0.png"),
						btnList: [{
								name: '了解更多',
								type: 'reverse',
								link: {
									pathName: 'smart0'
								}
							},
							{
								name: '关注订制',
								type: 'online',
								link: {
									pathName: 'smart0Intention'
								}
							},
						]
					},
					{
						image: require("../../assets/images/index2022/P1.jpg"),
						btnList: [{
								name: '立即预定',
								type: 'reverse',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
							{
								name: '小院在线',
								type: 'online',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
							{
								name: '了解更多',
								type: 'more',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
						]
					},
					// {
					// 	image: require("../../assets/images/index2022/P3.jpg"),
					// 	btnList: [{
					// 			name: '立即预定',
					// 			type: 'reverse',
					// 			link: {
					// 				url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=530'
					// 			}
					// 		},
					// 		{
					// 			name: '小院在线',
					// 			type: 'online',
					// 			link: {
					// 				url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=530'
					// 			}
					// 		},
					// 		{
					// 			name: '了解更多',
					// 			type: 'more',
					// 			link: {
					// 				url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=530'
					// 			}
					// 		},
					// 	]
					// }
				],
				list: [{
						iframesrc: 'https://app.modaiyun.com/damf/rendering-viewer/pano/LxXcz7uWsx?isRoaming=true',
						image: require("../../assets/images/index2022/smart0.png"),
						type:'iframe',
						btnList: [{
								name: '了解更多',
								type: 'reverse',
								link: {
									pathName: 'smart0'
								}
							},
							{
								name: '关注订制',
								type: 'online',
								link: {
									pathName: 'smart0Intention'
								}
							},
						]
					},
					{
						videosrc: 'http://v.xiaoyuan.com.cn/af25917fvodtranscq1257793586/e193139e387702299253458735/v.f100030.mp4',
						type:'video',
						btnList: [{
								name: '立即预定',
								type: 'reverse',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
							{
								name: '小院在线',
								type: 'online',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
							{
								name: '了解更多',
								type: 'more',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
						]
					},
					{
						iframesrc: ' https://www.kujiale.com/xiaoguotu/pano/3FNY1P5ODAYF',
						image: require("../../assets/images/index2022/P1.jpg"),
						type:'iframe',
						btnList: [{
								name: '立即预定',
								type: 'reverse',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=452'
								}
							},
							{
								name: '小院在线',
								type: 'online',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=452'
								}
							},
							{
								name: '了解更多',
								type: 'more',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=452'
								}
							},
						]
					},
					{
						iframesrc: 'https://yun.kujiale.com/cloud/design/3FO437CCRGRY/show',
						// image: require("../../assets/images/index2022/P3.jpg"),2024年5月7日要求官网不出现高层信息，暂用小院替代
						image: require("../../assets/images/index2022/P1.jpg"),
						type:'iframe',
						btnList: [{
								name: '立即预定',
								type: 'reverse',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=512'
								}
							},
							{
								name: '小院在线',
								type: 'online',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=512'
								}
							},
							{
								name: '了解更多',
								type: 'more',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=512'
								}
							},
						]
					},
					{
						image: require("../../assets/images/index2022/P4.jpg"),
						type:'img',
						btnList: [{
							name: '小院在线',
							type: 'online',
							link: {
								url: 'http://mall.xiaoyuan.com.cn'
							}
						}, ]
					},
					{
						image: require("../../assets/images/index2022/P5.png"),
						type:'img',
						btnList: [{
							name: '了解更多',
							type: 'more',
							link: {
								pathName: 'starbucks'
							}
						}, ]
					},
					{
						image: require("../../assets/images/index2022/P6.jpg"),
						type:'img',
						btnList: [{
							name: '了解更多',
							type: 'more',
							link: {
								pathName: 'library'
							}
						}, ]
					},
					{
						image: require("../../assets/images/index2022/P7.jpg"),
						type:'img',
						btnList: [{
							name: '了解更多',
							type: 'more',
							link: {
								pathName: 'fitness'
							}
						}, ]
					},
					{
						image: require("../../assets/images/index2022/P8.jpg"),
						type:'img',
						btnList: [{
							name: '了解更多',
							type: 'more',
							link: {
								pathName: 'sharedOffice'
							}
						}, ]
					},
				]
			}
		},
		// watch: {
		// 	// 监听事件，表单页面关闭时结束视频播放
		// 	'visible': function(val) {
		// 		if (this.visible === false) { //visible为表单显示状态
		// 			let myVideo = document.getElementById('video') //对应video标签的ID
		// 			myVideo.pause()
		// 		}
		// 	}
		// },
		created() {
			this.browser = myBrowser();
			const videoEle = document.getElementById('video');
			if(videoEle && videoEle.className == 'fullscreenVideo'){
			  videoEle.load();
			  videoEle.play();
			}
		},
		activated() {
			this.$refs.fullpage.init();
		},
		mounted() {
			// 在外部vue的window上添加postMessage的监听，并且绑定处理函数handleMessage
			window.addEventListener('message', event => {
				// 根据上面制定的结构来解析iframe内部发回来的数据
				const data = event.data
				switch (data.cmd) {
					case 'returnFormJson':
						// 业务逻辑
						break
					case 'returnHeight':
						// 业务逻辑
						break
				}
			});
			this.iframeWin = this.$refs.iframe.contentWindow
		},
		beforeRouteLeave(to, from, next) {
			this.$refs.fullpage.destroy('all');
			next();
		},
		methods: {
			change: function(e) {
				this.bannerIndex = e;
			},
			sendMessage() {
				// 外部vue向iframe内部传数据
				this.iframeWin.postMessage({
					cmd: 'getFormJson',
					params: {}
				}, '*')
			},
			afterLoad: function(origin, destination, direction) {
				this.current = destination.index;
			},
			btntap: utils.debounce(function(e) {
				if (e.url) {
					window.open(e.url)
					return;
				}

				if (e.pathName == this.$route.name) {
					this.reload()
				}
				if (e.pathName) {
					this.$router.push({
						name: e.pathName
					});
				}
			}, 200, true),
		}
	}
</script>

<style lang="scss" scoped>
	.foot {
		position: absolute;
		bottom: 0;
		width: 100%;
		// z-index: 9;
	}

	.videoContainer {
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: -100;
	}

	#bgVid {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}

	.videoContainer:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		z-index: -1;
		top: 0;
		left: 0;
		background: rgba(25, 29, 34, .65);
	}

	.index-wrap {
		overflow-x: hidden;
		overflow-y: hidden;

		.section {
			position: relative;

			.image-wrap {
				height: 100%;
				width: 100%;
			}

			.el-image__inner--center {
				top: 0 !important;
				left: 0 !important;
				transform: translate(0%, 0%) !important;
			}

			.image {
				width: 100%;
				object-fit: cover;
				height: 100%;
			}

			.btn-wrap {
				position: absolute;
				bottom: 100px;
				width: 100%;
				display: flex;
				justify-content: center;

				.animate__animated {
					&.animate__fadeInDown {
						--animate-duration: 2s;
					}
				}

				.btn {
					width: 256px;
					height: 40px;
					border-radius: 20px;
					font-size: 12px;
					line-height: 40px;
					text-align: center;
					margin: 0 20px;
					cursor: pointer;

					&.online {
						background: #222222;
						color: #FFFFFF;
					}

					&.more {
						background: #FFFFFF;
						color: #393C41;
					}

					&.reverse {
						background: #FFFFFF;
						color: #393C41;
					}
				}
			}
		}

	}
</style>